import {
  BasicInfoFilterList,
  ContactInfoFilterList,
  YearsOfExperience,
  EducationExperienceFilterList,
  WorkExperienceFilterList,
  OthersFilterList
} from "../constants";

const parseFQ = fq_string => {
  let re = "(*:*)$";
  let result = re.test(fq_string);
  // console.log(result);
};

/*
	Gives the Search params Value, pass window.location.search
	For url: localhost:3000?q=(name:刘五)&fq=(school_name:浙江大学)&fq=(major:[数学, 统计]&fq=(company_name:百度&title:数据科学家))
	returns { "query": {"name": "刘五"}, "filter": {"school_name": "浙江大学", "major":["数学", "统计"], company_name:"百度"}}
*/
export const decodeFilter = filter_string => {
  let filters = [];
  if (!filter_string) {
    return filters;
  }
  try {
    // filters = JSON.parse(atob(filter_string));
    filters = JSON.parse(decodeURIComponent(atob(filter_string)));
  } catch (err) {
    filters = [];
  }
  return filters;
};

export const encodeFilter = filters => {
  // let filter_string = btoa(JSON.stringify(filters))
  let filter_string = btoa(encodeURIComponent(JSON.stringify(filters)));
  return filter_string;
};

export function constructUrl(search_term, search_target, filter_string, page) {
  let search_term_part = "";
  let search_target_part = "";
  let filter_string_part = "";
  let page_part = "";
  let query_list = [];
  if (search_term) {
    search_term_part = `q=${search_term}`;
    query_list.push(search_term_part);
  }
  if (search_target || "main") {
    search_target_part = `search_target=${search_target}`;
    query_list.push(search_target_part);
  }
  if (filter_string) {
    filter_string_part = `fs=${filter_string}`;
    query_list.push(filter_string_part);
  }
  if (page) {
    page_part = `page=${page}`;
    query_list.push(page_part);
  }
  let query_string = query_list.join("&");
  return query_string;
}

export function constructFilter(filter_data) {
  let BasicInfoFilterKey = BasicInfoFilterList.map(x => x["name"]);
  let ContactInfoFilterKey = ContactInfoFilterList.map(x => x["name"]);
  let YearsOfExperienceFilterKey = YearsOfExperience.map(x => x["name"]);
  let EducationExperienceFilterKey = EducationExperienceFilterList.map(
    x => x["name"]
  );
  let WorkExperienceFilterKey = WorkExperienceFilterList.map(x => x["name"]);
  let OthersFilterKey = OthersFilterList.map(x => x["name"]);
  let result = [];
  let basic_info_result = [];
  let contact_info_result = [];
  let years_of_experience = [];
  let education_result = [];
  let work_result = [];
  let others_result = [];
  for (let i = 0; i < BasicInfoFilterKey.length; i++) {
    if (
      filter_data[BasicInfoFilterKey[i]] &&
      filter_data[BasicInfoFilterKey[i]].length > 0
    ) {
      let temp_data = {};
      temp_data[BasicInfoFilterKey[i]] = filter_data[BasicInfoFilterKey[i]];
      basic_info_result.push(temp_data);
    }
  }
  for (let i = 0; i < ContactInfoFilterKey.length; i++) {
    if (
      filter_data[ContactInfoFilterKey[i]] &&
      filter_data[ContactInfoFilterKey[i]].length > 0
    ) {
      let temp_data = {};
      temp_data[ContactInfoFilterKey[i]] = filter_data[ContactInfoFilterKey[i]];
      contact_info_result.push(temp_data);
    }
  }
  for (let i = 0; i < YearsOfExperienceFilterKey.length; i++) {
    if (
      filter_data[YearsOfExperienceFilterKey[i]] &&
      filter_data[YearsOfExperienceFilterKey[i]].length > 0
    ) {
      let temp_data = {};
      temp_data[YearsOfExperienceFilterKey[i]] =
        filter_data[YearsOfExperienceFilterKey[i]];
      years_of_experience.push(temp_data);
    }
  }
  let education_result_staging = [];
  for (let i = 0; i < EducationExperienceFilterKey.length; i++) {
    if (
      filter_data[EducationExperienceFilterKey[i]] &&
      filter_data[EducationExperienceFilterKey[i]].length > 0 &&
      EducationExperienceFilterKey[i] !== "school_name_exact"
    ) {
      if (EducationExperienceFilterKey[i] === "school_name") {
        if (filter_data["school_name_exact"]) {
          let temp_data = {};
          temp_data["school_name.keyword"] =
            filter_data[EducationExperienceFilterKey[i]];
          education_result_staging.push(temp_data);
        } else {
          let temp_data = {};
          temp_data[EducationExperienceFilterKey[i]] =
            filter_data[EducationExperienceFilterKey[i]];
          education_result_staging.push(temp_data);
        }
      } else {
        let temp_data = {};
        temp_data[EducationExperienceFilterKey[i]] =
          filter_data[EducationExperienceFilterKey[i]];
        education_result_staging.push(temp_data);
      }
    }
    if (filter_data["use_one_education"] === "true") {
      education_result = {};
      for (let j = 0; j < education_result_staging.length; j++) {
        education_result = {
          ...education_result,
          ...education_result_staging[j]
        };
      }
      education_result = [education_result];
    } else {
      education_result = education_result_staging;
    }
  }
  let work_result_staging = [];
  for (let i = 0; i < WorkExperienceFilterKey.length; i++) {
    if (
      filter_data[WorkExperienceFilterKey[i]] &&
      filter_data[WorkExperienceFilterKey[i]].length > 0 &&
      WorkExperienceFilterKey[i] !== "company_name_exact" &&
      WorkExperienceFilterKey[i] !== "job_title_exact"
    ) {
      if (WorkExperienceFilterKey[i] === "company_name") {
        if (filter_data["company_name_exact"]) {
          let temp_data = {};
          temp_data["company_name.keyword"] =
            filter_data[WorkExperienceFilterKey[i]];
          work_result_staging.push(temp_data);
        } else {
          let temp_data = {};
          temp_data[WorkExperienceFilterKey[i]] =
            filter_data[WorkExperienceFilterKey[i]];
          work_result_staging.push(temp_data);
        }
      } else if (WorkExperienceFilterKey[i] === "job_title") {
        if (filter_data["job_title_exact"]) {
          let temp_data = {};
          temp_data["job_title.keyword"] =
            filter_data[WorkExperienceFilterKey[i]];
          work_result_staging.push(temp_data);
        } else {
          let temp_data = {};
          temp_data[WorkExperienceFilterKey[i]] =
            filter_data[WorkExperienceFilterKey[i]];
          work_result_staging.push(temp_data);
        }
      } else {
        let temp_data = {};
        temp_data[WorkExperienceFilterKey[i]] =
          filter_data[WorkExperienceFilterKey[i]];
        work_result_staging.push(temp_data);
      }
    }
    if (filter_data["use_one_work_exp"] === "true") {
      work_result = {};
      for (let j = 0; j < work_result_staging.length; j++) {
        work_result = { ...work_result, ...work_result_staging[j] };
      }
      work_result = [work_result];
    } else {
      work_result = work_result_staging;
    }
  }
  for (let i = 0; i < OthersFilterKey.length; i++) {
    if (
      filter_data[OthersFilterKey[i]] &&
      filter_data[OthersFilterKey[i]].length > 0
    ) {
      let temp_data = {};
      if (
        filter_data[OthersFilterKey[i] + "_or"] &&
        filter_data[OthersFilterKey[i] + "_or"]
      ) {
        temp_data[OthersFilterKey[i] + ".or"] = filter_data[OthersFilterKey[i]];
      } else {
        temp_data[OthersFilterKey[i]] = filter_data[OthersFilterKey[i]];
      }
      others_result.push(temp_data);
    }
  }
  result = [
    ...basic_info_result,
    ...contact_info_result,
    ...years_of_experience,
    ...education_result,
    ...work_result,
    ...others_result
  ];
  return result;
}

export function parseFilter(filter_terms) {
  let filter_data = {};
  for (let i = 0; i < filter_terms.length; i++) {
    let temp_term = filter_terms[i];
    if (Object.keys(temp_term).length > 1) {
      let temp_keys = Object.keys(temp_term);
      if (
        temp_keys.includes("school_name") ||
        temp_keys.includes("school_name.key") ||
        temp_keys.includes("degree") ||
        temp_keys.includes("courses") ||
        temp_keys.includes("major") ||
        temp_keys.includes("school_level")
      ) {
        filter_data["use_one_education"] = "true";
      } else if (
        temp_keys.includes("company_name") ||
        temp_keys.includes("company_name.key") ||
        temp_keys.includes("job_function") ||
        temp_keys.includes("industry") ||
        temp_keys.includes("job_title") ||
        temp_keys.includes("job_title.key") ||
        temp_keys.includes("department") ||
        temp_keys.includes("work_description")
      ) {
        filter_data["use_one_education"] = "true";
      }
      for (let j = 0; j < temp_keys; j++) {
        if (temp_keys[j] === "company_name.key") {
          filter_data["company_name"] = temp_term[temp_keys[j]];
        } else if (temp_keys[j] === "school_name.key") {
          filter_data["school_name"] = temp_term[temp_keys[j]];
        } else if (temp_keys[j] === "job_title.key") {
          filter_data["job_title"] = temp_term[temp_keys[j]];
        } else {
          filter_data[temp_keys[j]] = temp_term[temp_keys[j]];
        }
      }
    } else {
      filter_data = { ...filter_data, ...temp_term };
    }
  }
  return filter_data;
}

export const parseSearchUrlTemp = url => {
  if (!url) {
    return {};
  }
  const searchParams = new URLSearchParams(url);
  let search_term = searchParams.get("q");
  const params = [...searchParams.entries()];
  let search_filter = params
    .filter(x => x[0] === "fs")
    .reduce(function(currentValue, [key, value]) {
      parseFQ(value);
      return currentValue.concat([value]);
    }, []);
  // console.log("search_filter", search_term, params);
  const allParamsObject = params.reduce(
    (allParam, [key, value]) => ({
      ...allParam,
      [key]: value
    }),
    {}
  );
  return allParamsObject;
};
