import React, { Component } from "react";
import { Pagination, Spin, Icon, Empty } from "antd";
import Header from "../../components/Header";
import CandidateList from "../../components/CandidateList";
import CandidateDetail from "../../components/CandidateDetail";
import LeftFilters from "../../components/LeftFilters";
import { getSearch, checkPurchased } from "../../actions/candidates";
import {
  encodeFilter,
  decodeFilter,
  constructUrl,
  constructFilter,
  parseFilter
} from "../../utils";
import "./style/index.less";

class CandidateListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_term: "",
      search_target: "main",
      filter_string: null,
      filter_terms: [],
      filter_data: {},
      candidates: [],
      count: 0,
      page: 0,
      size: 10,
      show_candidate: false,
      show_candidate_id: null,
      filter_visibility: false,
      candidate_data: {},
      loading: false,
      error: false
    };
  }

  componentDidMount() {
    let paramsString = this.props.location.search;
    let params = new URLSearchParams(paramsString);
    let search_term = params.get("q");
    let search_target = params.get("target") || "main";
    let page = parseInt(params.get("page"));
    let filter_visibility = false;
    let filter_data = {};
    if (!page) {
      page = 0;
    }
    let filter_string = params.get("fs");
    let filter_terms = [];
    if (filter_string) {
      filter_terms = decodeFilter(filter_string);
    }
    if (filter_terms.length > 0) {
      filter_visibility = true;
      filter_data = parseFilter(filter_terms);
    }
    this.setState({
      search_term: search_term,
      search_target: search_target,
      filter_terms: filter_terms,
      filter_string: filter_string,
      filter_data: filter_data,
      page: page,
      filter_visibility: filter_visibility
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.search_term !== this.state.search_term ||
      prevState.search_target !== this.state.search_target ||
      prevState.page !== this.state.page
    ) {
      let search_query = {};
      search_query[this.state.search_target] = this.state.search_term || "";
      this.setState({
        loading: true,
        error: false
      });
      getSearch(
        [search_query],
        this.state.filter_terms,
        this.state.size,
        this.state.page * this.state.size,
        this.cb.bind(this),
        this.cb_error.bind(this)
      );
    } else if (prevState.filter_string !== this.state.filter_string) {
      let filter_terms = decodeFilter(this.state.filter_string);
      let filter_data = {};
      if (filter_terms.length > 0) {
        filter_data = parseFilter(filter_terms);
      }
      if (this.arraysNotMatch(filter_terms, prevState.filter_terms)) {
        // console.log(
        //   "filters",
        //   filter_terms,
        //   prevState.filter_terms,
        //   this.arraysNotMatch(filter_terms, prevState.filter_terms)
        // );
        let search_query = {};
        search_query[this.state.search_target] = this.state.search_term || "";
        this.setState({
          loading: true,
          error: false,
          filter_data: filter_data,
          filter_string: this.state.filter_string,
          filter_terms: filter_terms
        });
        getSearch(
          [search_query],
          filter_terms,
          this.state.size,
          this.state.page * this.state.size,
          this.cb.bind(this),
          this.cb_error.bind(this)
        );
      }
    }
  }

  arraysNotMatch(arr1, arr2) {
    if (arr1.length !== arr2.length) return true;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return true;
    }
    return false;
  }

  handleSearchEnter(search_term) {
    if (search_term !== this.state.search_term) {
      let target_url =
        "search?" +
        constructUrl(
          search_term,
          this.state.search_target,
          this.state.filter_string,
          0
        );
      this.props.history.push(target_url);
      // window.location.reload();
      console.log("enter page");
      this.setState({
        search_term: search_term,
        page: 0
      });
    }
  }

  changeFilterVisibility() {
    let previous_filter_visibility = this.state.filter_visibility;
    this.setState({
      filter_visibility: !previous_filter_visibility
    });
  }

  onChangeFilterData(data) {
    let filter_terms = constructFilter(data);
    let filter_string = encodeFilter(filter_terms);
    if (this.arraysNotMatch(filter_terms, this.state.filter_terms)) {
      this.setState({
        filter_data: data,
        filter_terms: filter_terms,
        filter_string: filter_string,
        page: 0
      });
      let target_url =
        "search?" +
        constructUrl(
          this.state.search_term,
          this.state.search_target,
          filter_string,
          0
        );
      this.props.history.push(target_url);
      // window.location.reload();
    }
  }

  onChangePage(e) {
    let page = e - 1;
    this.setState({
      page: page
    });
    let target_url =
      "search?" +
      constructUrl(
        this.state.search_term,
        this.state.search_target,
        this.state.filter_string,
        page
      );
    this.props.history.push(target_url);
    // window.location.reload();
  }

  showCandidateDetailDrawer(item) {
    this.setState({
      show_candidate: true,
      show_candidate_id: item.hash_id,
      candidate_data: item
    });
  }

  onCandidateDetailDrawerClose() {
    this.setState({
      show_candidate: false,
      show_candiddate_id: null
    });
  }

  cb(data) {
    this.setState({
      candidates: data.resumes,
      count: data.hits,
      loading: false,
      error: false
    });
    let hash_ids = data.resumes.map(x => x.hash_id);
    checkPurchased(hash_ids, this.checkPurchasedCb.bind(this));
  }

  cb_error() {
    this.setState({
      candidates: [],
      count: 0,
      loading: false,
      error: true
    });
  }

  checkPurchasedCb(data) {
    // console.log(data);
    let { candidates } = this.state;
    for (let i = 0; i < data.length; i++) {
      let candidateIndex = candidates.findIndex(
        x => x.hash_id === data[i].hash_id
      );
      let candidateData = candidates[candidateIndex];
      candidateData["parsing_result"]["purchased"] = true;
      candidateData["parsing_result"]["contact_info"]["phone_number"] =
        data[i].phone_number;
      candidateData["parsing_result"]["contact_info"]["email"] = data[i].email;
      candidates[candidateIndex] = candidateData;
    }
    this.setState({ candidates });
  }

  updateCandidateContactInfo(data) {
    let { candidate_data, candidates, show_candidate_id } = this.state;
    let {
      cv_id,
      cv_name,
      english_parsing_result,
      hash_id,
      parsing_result,
      predicted_result,
      tags
    } = data;
    let candidateIndex = candidates.findIndex(
      x => x.hash_id === show_candidate_id
    );
    parsing_result["purchased"] = true;
    candidate_data = {
      cv_id,
      cv_name,
      english_parsing_result,
      hash_id,
      parsing_result,
      predicted_result,
      tags
    };
    candidates[candidateIndex] = candidate_data;
    this.setState({
      candidate_data,
      candidates
    });
  }

  render() {
    // console.log(this.state);
    const antIcon = <Icon type="loading" style={{ fontSize: 30 }} spin />;
    return (
      <div className="candidate-list-page">
        <Header
          search_term={this.state.search_term}
          changeFilterVisibility={this.changeFilterVisibility.bind(this)}
          onPressSearchEnter={this.handleSearchEnter.bind(this)}
          history={this.props.history}
          filter_count={this.state.filter_terms.length}
        />
        <div className="candidate-list-page-body">
          {this.state.filter_visibility ? (
            <LeftFilters
              data={this.state.filter_data}
              onChangeData={this.onChangeFilterData.bind(this)}
            />
          ) : (
            <div style={{ width: "200px" }}></div>
          )}
          {false ? (
            <div className="candidate-list-page-body-loading">
              <Spin size="large" indicator={antIcon} />
              <div className="candidate-list-page-body-loading-text">
                搜索中...
              </div>
            </div>
          ) : this.state.error ? (
            <div className="candidate-list-page-body-loading">
              <Icon
                type="close-circle"
                style={{ color: "#ff6644", fontSize: "30px" }}
              />
              <div className="candidate-list-page-body-error-text">
                搜索失败，请重试...
              </div>
            </div>
          ) : (
            <div className="candidate-list-page-body-div">
              {this.state.count || this.state.loading ? (
                <div className="candidate-list-page-body-result">
                  {!this.state.loading ? (
                    <p className="candidate-list-page-body-result-count">
                      第{this.state.page * this.state.size + 1}-
                      {(this.state.page + 1) * this.state.size}位候选人， 共
                      {this.state.count}位
                    </p>
                  ) : null}
                  <CandidateList
                    candidates={this.state.candidates}
                    loading={this.state.loading}
                    showCandidate={this.showCandidateDetailDrawer.bind(this)}
                  />
                  {!this.state.loading ? (
                    <div className="candidate-list-page-body-result-pagination">
                      <Pagination
                        current={this.state.page + 1}
                        total={this.state.count}
                        onChange={this.onChangePage.bind(this)}
                      />
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="candidate-list-page-body-result">
                  <div className="candidate-list-page-body-result-empty">
                    <Empty description={"未搜到候选人"} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <CandidateDetail
          visible={this.state.show_candidate}
          detail={this.state.candidate_data}
          resume_id={this.state.show_candidate_id}
          updateCandidateContactInfo={this.updateCandidateContactInfo.bind(
            this
          )}
          onClose={this.onCandidateDetailDrawerClose.bind(this)}
        />
      </div>
    );
  }
}

export default CandidateListPage;
