import React, { Component } from "react";
import store from "store";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import "./login.css";
import isLoggedIn from "../../utils/isLoggedIn";
import { DEPLOYMENT_ENV } from "../../config";
import { getPlanList } from "../../actions/candidates";
import { message } from "antd";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
    this.onLogin = this.onLogin.bind(this);
  }

  onLogin(e) {
    e.preventDefault();
    const { username, password } = this.state;
    const { history } = this.props;
    // console.log(DEPLOYMENT_ENV);
    if (DEPLOYMENT_ENV === "internal") {
      if (!(username === "demo" && password === "Demo123")) {
        message.error("账号/密码错误");
      } else {
        store.set("loggedIn", true);
        message.success("登录成功！");
        this.props.history.push("/");
      }
    } else if (DEPLOYMENT_ENV === "external") {
      console.log(username, password);
      getPlanList(username, password, this.onExternalLoginSuccess.bind(this));
    }
  }

  onExternalLoginSuccess(data) {
    // let plan_search = data.filter(x => x.product_id === 1)
    let plan_search = data.filter(x => x.product_name === "简历获取");
    if (plan_search.length === 0) {
      message.error("账户过期");
    } else {
      let plan = plan_search[0];
      if (plan["left_quota"] === 0) {
        message.error("账户余额不足");
      } else {
        message.success("登录成功！");
        store.set("user_id", this.state.username);
        store.set("user_secret", this.state.password);
        this.props.history.push("/");
      }
    }
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  render() {
    return (
      <div
        id={DEPLOYMENT_ENV === "external" ? "jianyue-login-div" : "login-div"}
      >
        <div className="login">
          {DEPLOYMENT_ENV === "external" ? (
            <div>
              <img
                src={require("../../assets/img/jianyue_white.png")}
                style={{ height: "60px", marginBottom: "10px" }}
                alt="简阅"
              />
            </div>
          ) : (
            <div>
              <img
                src={require("../../assets/img/logo_strong.png")}
                style={{ height: "60px" }}
                alt="小析智能"
              />
              <h1 style={{ fontWeight: "400" }}>小析智能搜索登录</h1>
            </div>
          )}
          <form onSubmit={this.onLogin} ref="login">
            <input
              className="login-input"
              type="text"
              ref="email"
              placeholder="用户名"
              required="required"
              onChange={this.onChangeUsername.bind(this)}
            />
            <input
              className="login-input"
              type="password"
              ref="pass"
              placeholder="密码"
              required="required"
              onChange={this.onChangePassword.bind(this)}
            />
            <div style={{ marginTop: "20px" }}>
              <button
                className="btn btn-primary btn-block btn-large"
                type="submit"
              >
                登陆
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func
};
