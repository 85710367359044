import React from "react";
import store from "store";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Input,
  Icon,
  Dropdown,
  Avatar,
  Menu,
  Tooltip,
  Badge
} from "antd";
import isLoggedIn from "../../utils/isLoggedIn";
import { DEPLOYMENT_ENV } from "../../config";
import "./style/index.less";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search_term: ""
    };
  }

  componentDidMount() {
    this.setState({
      search_term: this.props.search_term
    });
    if (!isLoggedIn()) {
      console.log("not login");
      this.props.history.push("/login");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.search_term !== this.props.search_term) {
      this.setState({
        search_term: this.props.search_term
      });
    }
  }

  changeSearchTerm(e) {
    this.setState({
      search_term: e.target.value
    });
  }

  onPressSearchEnter(e) {
    if (this.props.onPressSearchEnter) {
      this.props.onPressSearchEnter(e.target.value);
    }
  }

  onPressSearchIcon(e) {
    if (this.props.onPressSearchEnter) {
      this.props.onPressSearchEnter(this.state.search_term);
    }
  }

  onLogout() {
    if (DEPLOYMENT_ENV === "internal") {
      store.set("loggedIn", false);
    } else if (DEPLOYMENT_ENV === "external") {
      store.remove("user_id");
      store.remove("user_secret");
    }
    // console.log(this.props.history);
    this.props.history.push("/login");
  }

  render() {
    const DropdownMenu = (
      <Menu>
        <Menu.Item key="logout">
          <a className="icon-dropdown-item" onClick={this.onLogout.bind(this)}>
            <Icon
              type="logout"
              style={{ marginRight: "16px", fontWeight: "600", color: "red" }}
            />
            登出
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        <div id="header" className="header">
          <Row>
            <Col xxl={20} xl={20} lg={20} md={20} sm={24} xs={24}>
              <div className="header-left-part">
                {DEPLOYMENT_ENV === "external" ? (
                  <a id="logo-jianyue" href="/">
                    <img
                      src={require("../../assets/img/jianyue_black.png")}
                      alt="logo"
                    />
                  </a>
                ) : (
                  <a id="logo" href="/">
                    <img
                      src={require("../../assets/img/logo_strong.png")}
                      alt="logo"
                    />
                    <span>小析智能</span>
                  </a>
                )}
                <div className="header-search-bar">
                  <Input
                    suffix={
                      <Icon
                        type="search"
                        style={{
                          color: "#6772e5",
                          fontSize: "18px",
                          paddingRight: "8px",
                          hover: "cursor"
                        }}
                        onClick={this.onPressSearchIcon.bind(this)}
                      />
                    }
                    value={this.state.search_term}
                    onChange={this.changeSearchTerm.bind(this)}
                    onPressEnter={this.onPressSearchEnter.bind(this)}
                  />
                </div>
                <div className="header-filter-button">
                  <Tooltip title="高级搜索" placement="right">
                    <Badge count={this.props.filter_count}>
                      <a
                        className="header-filter-botton-icon"
                        onClick={this.props.changeFilterVisibility.bind(this)}
                      >
                        <svg
                          className="icon"
                          focusable="false"
                          viewBox="0 0 24 24"
                        >
                          <path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path>
                        </svg>
                      </a>
                    </Badge>
                  </Tooltip>
                </div>
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={4} sm={0} xs={0}>
              <div className="header-meta">
                <div id="preview">
                  <Dropdown overlay={DropdownMenu} trigger={["click"]}>
                    <Avatar
                      style={{
                        color: "#fff",
                        backgroundColor: "rgb(245, 106, 0)",
                        cursor: "pointer"
                      }}
                    >
                      智
                    </Avatar>
                  </Dropdown>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="header-empty-section" />
      </div>
    );
  }
}

export default Header;
Header.propTypes = {
  search_term: PropTypes.string.isRequired,
  onPressSearchEnter: PropTypes.func,
  changeFilterVisibility: PropTypes.func,
  history: PropTypes.object,
  filter_count: PropTypes.number
};
