import React from "react";
import PropTypes from "prop-types";
import {
  BasicInfoFilterList,
  ContactInfoFilterList,
  YearsOfExperience,
  EducationExperienceFilterList,
  WorkExperienceFilterList,
  OthersFilterList} from "../../constants";
import {
  Input,
  message,
  Select,
  Row,
  Col,
  Button,
  Radio,
  Slider,
  Checkbox,
  Divider,
  Icon,
  Switch
} from "antd";
import "./style/index.less";

const CheckboxGroup = Checkbox.Group;
const { TextArea } = Input;
const { Option } = Select;



class LeftFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: [],
      gender: [],
      min_age: [],
      max_age: [],
      current_location: [],
      expect_location: [],
      current_position: [],
      desired_position: [],
      political_status: [],
      industry: [],
      current_company: [],
      early_graduate_year: [],
      latest_graduate_year: [],
      phone_number: [],
      email: [],
      QQ: [],
      wechat: [],
      min_work_exp: [],
      max_work_exp: [],
      school_name: [],
      degree: [],
      major: [],
      school_level: [],
      abroad_country: [],
      abroad: [],
      courses: [],
      company_name: [],
      job_function: [],
      industry: [],
      job_title: [],
      department: [],
      work_description: [],
      social_description: [],
      project_name: [],
      project_description: [],
      organization_name: [],
      training_description: [],
      resume_rawtext: [],
      skills: [],
      it_skills: [],
      business_skills: [],
      language: [],
      certificate: [],
      awards: [],
      self_evaluation: [],
      school_name_exact: false,
      company_name_exact: false,
      job_title_exact: false,
      resume_rawtext_or: false,
      social_description_or: false,
      project_description_or: false,
      training_description_or: false,
      skills_or: false,
      it_skills_or: false,
      business_skills_or: false,
      language_or: false,
      certificate_or: false,
      awards_or: false,
      use_one_education: "false",
      use_one_work_exp: "false"
    };
    this.onClickSwitch = this.onClickSwitch.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    this.updateStateData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.name !== this.state.name ||
        prevState.gender !== this.state.gender ||
        prevState.min_age !== this.state.min_age ||
        prevState.max_age !== this.state.max_age ||
        prevState.current_location !== this.state.current_location ||
        prevState.expect_location !== this.state.expect_location ||
        prevState.current_position !== this.state.current_position ||
        prevState.desired_position !== this.state.desired_position ||
        prevState.political_status !== this.state.political_status ||
        prevState.industry !== this.state.industry ||
        prevState.current_company !== this.state.current_company ||
        prevState.early_graduate_year !== this.state.early_graduate_year ||
        prevState.latest_graduate_year !== this.state.latest_graduate_year ||
        prevState.phone_number !== this.state.phone_number ||
        prevState.email !== this.state.email ||
        prevState.QQ !== this.state.QQ ||
        prevState.wechat !== this.state.wechat ||
        prevState.min_work_exp !== this.state.min_work_exp ||
        prevState.max_work_exp !== this.state.max_work_exp ||
        prevState.school_name !== this.state.school_name ||
        prevState.degree !== this.state.degree ||
        prevState.major !== this.state.major ||
        prevState.school_level !== this.state.school_level ||
        prevState.courses != this.state.courses ||
        prevState.abroad != this.state.abroad ||
        prevState.abroad_country != this.state.abroad_country ||
        prevState.company_name !== this.state.company_name ||
        prevState.job_function !== this.state.job_function ||
        prevState.industry !== this.state.industry ||
        prevState.job_title !== this.state.job_title ||
        prevState.department !== this.state.department ||
        prevState.work_description !== this.state.work_description ||
        prevState.social_description !== this.state.social_description ||
        prevState.project_name !== this.state.project_name ||
        prevState.project_description !== this.state.project_description ||
        prevState.organization_name !== this.state.organization_name ||
        prevState.training_description !== this.state.training_description ||
        prevState.resume_rawtext !== this.state.resume_rawtext ||
        prevState.skills !== this.state.skills ||
        prevState.it_skills !== this.state.it_skills ||
        prevState.business_skills !== this.state.business_skills ||
        prevState.language !== this.state.language ||
        prevState.certificate !== this.state.certificate ||
        prevState.awards !== this.state.awards ||
        prevState.self_evaluation !== this.state.self_evaluation ||
        prevState.school_name_exact !== this.state.school_name_exact ||
        prevState.company_name_exact !== this.state.company_name_exact ||
        prevState.job_title_exact !== this.state.job_title_exact ||
        prevState.resume_rawtext_or !== this.state.resume_rawtext_or ||
        prevState.skills_or !== this.state.skills_or ||
        prevState.it_skills_or !== this.state.it_skills_or ||
        prevState.business_skills_or !== this.state.business_skills_or ||
        prevState.language_or !== this.state.language_or ||
        prevState.certificate_or !== this.state.certificate_or ||
        prevState.awards_or !== this.state.awards_or ||
        prevState.social_description_or !== this.state.social_description_or ||
        prevState.project_description_or !== this.state.project_description_or ||
        prevState.training_description_or !== this.state.training_description_or ||
        prevState.use_one_education !== this.state.use_one_education ||
        prevState.use_one_work_exp !== this.state.use_one_work_exp
      ) {
        this.props.onChangeData(this.state)
      }
  }

  updateStateData() {
    this.setState({
      name: this.props.data.name || [],
      gender: this.props.data.gender || [],
      min_age: this.props.data.min_age || [],
      max_age: this.props.data.max_age || [],
      current_location: this.props.data.current_location || [],
      expect_location: this.props.data.expect_location || [],
      current_position: this.props.data.current_position || [],
      desired_position: this.props.data.desired_position || [],
      political_status: this.props.data.political_status || [],
      industry: this.props.data.industry || [],
      current_company: this.props.data.current_company || [],
      early_graduate_year: this.props.data.early_graduate_year || [],
      latest_graduate_year: this.props.data.latest_graduate_year || [],
      phone_number: this.props.data.phone_number || [],
      email: this.props.data.email || [],
      QQ: this.props.data.QQ || [],
      wechat: this.props.data.wechat || [],
      min_work_exp: this.props.data.min_work_exp || [],
      max_work_exp: this.props.data.max_work_exp || [],
      school_name: this.props.data.school_name || [],
      degree: this.props.data.degree || [],
      major: this.props.data.major || [],
      school_level: this.props.data.school_level || [],
      courses: this.props.data.courses || [],
      abroad_country: this.props.data.abroad_country || [],
      abroad: this.props.data.abroad || [],
      company_name: this.props.data.company_name || [],
      job_function: this.props.data.job_function || [],
      industry: this.props.data.industry || [],
      job_title: this.props.data.job_title || [],
      department: this.props.data.department || [],
      work_description: this.props.data.work_description || [],
      social_description: this.props.data.social_description || [],
      project_name: this.props.data.project_name || [],
      project_description: this.props.data.project_description || [],
      organization_name: this.props.data.organization_name || [],
      training_description: this.props.data.training_description || [],
      resume_rawtext: this.props.data.resume_rawtext || [],
      skills: this.props.data.skills || [],
      it_skills: this.props.data.it_skills || [],
      business_skills: this.props.data.business_skills || [],
      language: this.props.data.language || [],
      certificate: this.props.data.certificate || [],
      awards: this.props.data.awards || [],
      self_evaluation: this.props.data.self_evaluation || [],
      self_evaluation: this.props.data.self_evaluation || [],
      school_name_exact: this.props.data.school_name_exact || false,
      company_name_exact: this.props.data.company_name_exact || false,
      job_title_exact: this.props.data.job_title_exact || false,
      resume_rawtext_or: this.props.data.resume_rawtext_or || false,
      skills_or: this.props.data.skills_or || false,
      it_skills_or: this.props.data.it_skills_or || false,
      business_skills_or: this.props.data.business_skills_or || false,
      language_or: this.props.data.language_or || false,
      certificate_or: this.props.data.certificate_or || false,
      awards_or: this.props.data.awards_or || false,
      social_description_or: this.props.data.social_description_or || false,
      project_description_or: this.props.data.project_description_or || false,
      training_description_or: this.props.data.training_description_or || false,
      use_one_education: this.props.data.use_one_education || "false",
      use_one_work_exp: this.props.data.use_one_work_exp || "false"
    });
  }

  onClickClear() {
    this.setState({
      name: [],
      gender: [],
      min_age: [],
      max_age: [],
      current_location: [],
      expect_location: [],
      current_position: [],
      desired_position: [],
      political_status: [],
      industry: [],
      current_company: [],
      early_graduate_year: [],
      latest_graduate_year: [],
      phone_number: [],
      email: [],
      QQ: [],
      wechat: [],
      min_work_exp: [],
      max_work_exp: [],
      school_name: [],
      degree: [],
      major: [],
      school_level: [],
      courses: [],
      abroad_country: [],
      abroad: [],
      company_name: [],
      job_function: [],
      industry: [],
      job_title: [],
      department: [],
      work_description: [],
      social_description: [],
      project_name: [],
      project_description: [],
      organization_name: [],
      training_description: [],
      skills: [],
      it_skills: [],
      business_skills: [],
      language: [],
      certificate: [],
      awards: [],
      self_evaluation: [],
      resume_rawtext: [],
      school_name_exact: false,
      company_name_exact: false,
      job_title_exact: false,
      resume_rawtext_or: false,
      skills_or: false,
      it_skills_or: false,
      business_skills_or: false,
      language_or: false,
      certificate_or: false,
      awards_or: false,
      social_description_or: false,
      project_description_or: false,
      training_description_or: false,
      use_one_education: "false",
      use_one_work_exp: "false"
    })
  }

  handleChange(name, e) {
    this.setState({
      [name]: e
    });
  }

  onClickSwitch(name, e) {
    this.setState({
      [name]: e
    });
  }

  onChangeSingleExp(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  generateFilters(filterList, state, onClickSwitch, onChange) {
    return filterList.map(function(item, key) {
      return (
        <div className="left-filter-div-form-row" key={key}>
          <div className="left-filter-div-form-name">
            <h5>{item["chinese_name"]}</h5>
            {item['has_exact_match'] ?
              <Switch checkedChildren="精准匹配"
                      unCheckedChildren="模糊匹配"
                      size="small"
                      name={`${item["name"]}_exact`}
                      checked={state[`${item["name"]}_exact`]}
                      onChange={onClickSwitch.bind(this, `${item["name"]}_exact`)} /> :
              null}
             {item['has_or_match'] ?
              <Switch checkedChildren="OR搜索"
                      unCheckedChildren="AND搜索"
                      size="small"
                      name={`${item["name"]}_or`}
                      checked={state[`${item["name"]}_or`]}
                      onChange={onClickSwitch.bind(this, `${item["name"]}_or`)} /> :
              null}
          </div>
          <div className="left-filter-div-form-value">
            <Select
              mode="tags"
              size="default"
              style={{ width: '100%' }}
              name={item["name"]}
              dropdownStyle={{height: 0, width: 0}}
              value={state[item["name"]]}
              onChange={onChange.bind(this, `${item["name"]}`)}
            >
              {[]}
            </Select>
          </div>
        </div>
      );
    })
  }

  render() {
    return (
      <div className="left-filter-div">
        <div className="left-filter-div-form">
          <div className="left-filter-div-title-div">
            <h3 className="left-filter-div-title">高级搜索</h3>
            <div className="left-filter-div-title-clear-button">
              <Button type="primary" onClick={this.onClickClear.bind(this)}>清空搜索</Button>
            </div>
          </div>
          <div className="left-filter-div-form-section">
            <Divider>基本信息</Divider>
            {this.generateFilters(BasicInfoFilterList, this.state, this.onClickSwitch, this.handleChange)}
          </div>
          <div className="left-filter-div-form-section">
            <Divider>联系方式</Divider>
            {this.generateFilters(ContactInfoFilterList, this.state, this.onClickSwitch, this.handleChange)}
          </div>
          <div className="left-filter-div-form-section">
          <Divider>工作年限</Divider>
            {this.generateFilters(YearsOfExperience, this.state, this.onClickSwitch, this.handleChange)}
          </div>
          <div className="left-filter-div-form-section">
            <Divider>教育经历</Divider>
            <div className="left-filter-div-form-switch">
              <Radio.Group value={this.state.use_one_education}
                           name="use_one_education"
                           buttonStyle="solid"
                           size="small"
                           onChange={this.onChangeSingleExp.bind(this)}>
                <Radio.Button value="false">不同经历</Radio.Button>
                <Radio.Button value="true">同段经历</Radio.Button>
              </Radio.Group>
            </div>
            {this.generateFilters(EducationExperienceFilterList, this.state, this.onClickSwitch, this.handleChange)}
          </div>
          <div className="left-filter-div-form-section">
            <Divider>工作经历</Divider>
            <div className="left-filter-div-form-switch">
              <Radio.Group value={this.state.use_one_work_exp}
                           name="use_one_work_exp"
                           buttonStyle="solid"
                           size="small"
                           onChange={this.onChangeSingleExp.bind(this)}>
                <Radio.Button value="false">不同经历</Radio.Button>
                <Radio.Button value="true">同段经历</Radio.Button>
              </Radio.Group>
            </div>
            {this.generateFilters(WorkExperienceFilterList, this.state, this.onClickSwitch, this.handleChange)}
          </div>
          <div className="left-filter-div-form-section">
            <Divider>其他</Divider>
            {this.generateFilters(OthersFilterList, this.state, this.onClickSwitch, this.handleChange)}
          </div>
        </div>
      </div>
    );
  }
}

export default LeftFilters;

LeftFilters.propTypes = {
  onChangeData: PropTypes.func,
  data: PropTypes.object
};
