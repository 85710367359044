import React, { Component } from "react";
import { Input } from "antd";
import isLoggedIn from "../../utils/isLoggedIn";
import { DEPLOYMENT_ENV } from "../../config";
import "./style/index.less";

class Home extends Component {
  handleSearchEnter = e => {
    let term = e.target.value;
    this.props.history.push(`search?q=${term}`);
  };

  componentDidMount() {
    if (!isLoggedIn()) {
      console.log("not login");
      this.props.history.push("/login");
    }
  }

  render() {
    return (
      <div
        className={
          DEPLOYMENT_ENV === "external"
            ? "home-page jianyue-home-page"
            : "home-page"
        }
      >
        <div className="home-page-div">
          {DEPLOYMENT_ENV === "external" ? (
            <div className="home-page-title">
              <img
                src={require("../../assets/img/jianyue_square.png")}
                alt="简阅"
              />
              <h1>智能人才库搜索</h1>
            </div>
          ) : (
            <div className="home-page-title">
              <img
                src={require("../../assets/img/logo_strong.png")}
                alt="小析智能"
              />
              <h1>智能人才库搜索</h1>
            </div>
          )}
          <Input
            className="home-page-search"
            onPressEnter={this.handleSearchEnter.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default Home;
