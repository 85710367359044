export const BASIC_INFO = {
  name: "姓名：",
  ethnic: "民族：",
  age: "年龄：",
  phone_number: "手机：",
  email: "邮箱：",
  QQ: "qq：",
  home_phone_number: "座机：",
  zipcode: "邮编：",
  birthplace: "籍贯：",
  wechat: "微信号：",
  gender: "性别：",
  date_of_birth: "生日：",
  degree: "学历：",
  major: "专业：",
  current_location: "现居地：",
  school_name: "毕业院校：",
  current_position: "当前职位：",
  industry: "所属行业：",
  num_work_experience: "工作时长：",
  current_company: "当前公司：",
  professional_level: "专业级别：",
  national_identity_number: "身份证号：",
  detailed_location: "详细地址：",
  current_status: "求职状态：",
  political_status: "政治面貌：",
  marital_status: "婚姻状况：",
  current_salary: "当前薪水：",
  desired_salary: "期望薪水：",
  desired_position: "期望职位：",
  desired_industry: "期望行业：",
  expect_location: "期望工作地点：",
  school_type: "毕业院校类别：",
  work_start_year: "开始工作年限："
};

export const EDUCATION = {
  location: "地点：",
  degree: "学位：",
  major: "专业：",
  department: "学院：",
  GPA: "GPA：",
  ranking: "排名：",
  school_level: "学校级别：",
  study_model: "上课模式：",
  school_rank: "学校排名：",
  courses: "所学课程：",
  abroad_country: "海外国家："
};

export const WORK = {
  location: "地点：",
  job_title: "职位名称：",
  department: "所属部门：",
  industry: "公司行业：",
  job_function: "职位职能：",
  company_size: "公司规模：",
  company_type: "公司类型：",
  salary: "工资水平："
};

export const SOCIAL = {
  location: "地点：",
  organization_name: "公司/学校/社团名：",
  department: "所属部门：",
  job_title: "职位名称："
};

export const PROJECT = {
  location: "地点：",
  job_title: "职位名称：",
  job_function: "所属职能：",
  company_name: "所属公司："
};

export const TRAINING = {
  location: "地点：",
  subject: "培训主题："
};

export const OTHERS = {
  skills: "专业技能：",
  it_skills: "IT技能：",
  business_skills: "商业技能：",
  language: "语言能力：",
  certificate: "专业证书：",
  awards: "荣誉奖项："
};

export const ERROR_HEADER = {
  90: "不支持该文件格式",
  91: "暂不支持图片文件解析",
  92: "请上传真实的简历文件",
  93: "暂不支持英文简历解析"
};

export const ERROR_DETAILS = {
  90: "SORRY...火星总部暂不支持此文件格式",
  91: "SORRY...图片简历解析功能马上就会发布哦",
  92: "SORRY...经火星总部判断，此文件不是真实简历文件",
  93: "SORRY...英文简历解析功能马上就会发布哦"
};

export const SALARY_MAP = {
  under_4000: "0 —— 4000",
  "4000_to_6000": "4000 —— 6000",
  "6000_to_8000": "6000 —— 8000",
  "8000_to_10000": "8000 —— 10000",
  "10000_to_15000": "10000 —— 15000",
  "15000_to_20000": "15000 —— 20000",
  "20000_to_30000": "20000 —— 30000",
  "30000_to_40000": "30000 —— 40000",
  "40000+": "40000+"
};
