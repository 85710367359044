import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import RouteWithSubRoutes from "./components/RouteWithSubRoutes";
import Home from "./containers/Home";
import Login from "./containers/Login";
import CandidateListPage from "./containers/CandidateListPage";

const componentRoutes = [
  {
    exact: true,
    path: "/",
    component: Home
  },
  {
    exact: true,
    path: "/login",
    component: Login
  },
  {
    path: "/search",
    exact: false,
    component: CandidateListPage
  }
];

const AppRouter = () => (
  <Router>
    <div className="root-div">
      {componentRoutes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </div>
  </Router>
);

export default AppRouter;
