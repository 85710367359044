import store from "store";
import { DEPLOYMENT_ENV } from "../config";

function isLoggedIn() {
  if (DEPLOYMENT_ENV === "internal") {
    return !!store.get("loggedIn");
  } else if (DEPLOYMENT_ENV === "external") {
    return store.get("user_id") && store.get("user_secret");
  }
}

export default isLoggedIn;
