import store from "store";
import { DEPLOYMENT_ENV, BACKEND_URL } from "../config";
import { message } from "antd";
// import { BACKEND_URL_TEST } from "../config";

export function getSearch(query, filter, limit, offset, cb, cb_error) {
  let url =
    DEPLOYMENT_ENV === "internal"
      ? `${BACKEND_URL}/search_resumes`
      : `${BACKEND_URL}/v1/resumer/search_resumes?id=${store.get(
          "user_id"
        )}&secret=${store.get("user_secret")}`;
  let data = {
    query: query,
    filter: filter,
    limit: limit,
    offset: offset
  };
  let headers;
  if (DEPLOYMENT_ENV === "internal") {
    headers = {
      "Content-Type": "application/json",
      AUTH: "peipei"
    };
  } else if (DEPLOYMENT_ENV === "external") {
    headers = {
      "Content-Type": "application/json"
      // "id": store.get("user_id"),
      // "secret": store.get("user_secret")
    };
  }
  return fetch(url, {
    method: "POST",
    mode: "cors",
    headers: headers,
    body: JSON.stringify(data)
  })
    .then(response => {
      if (!response.ok) {
        return null;
      }
      return response.json();
    })
    .then(json => {
      if (json) {
        // console.log("this", json)
        cb(json);
        message.success("成功");
      } else {
        message.error("失败");
        cb_error();
      }
    })
    .catch(error => {
      message.error("失败");
      cb_error();
    });
}

export function getPlanList(user_id, user_secret, cb) {
  let url = `${BACKEND_URL}/v1/plan?id=${user_id}&secret=${user_secret}`;
  return fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(response => {
      if (!response.ok) {
        message.error("账户密码不正确");
        return null;
      }
      return response.json();
    })
    .then(json => {
      if (json) {
        // console.log(json)
        cb(json);
        // message.success("成功");
      } else {
        // message.error("失败");
      }
    })
    .catch(error => {
      message.error("失败");
    });
}

export function purchaseResume(resume_id, cb) {
  let url = `${BACKEND_URL}/v1/resumer/buy_contact?id=${store.get(
    "user_id"
  )}&secret=${store.get("user_secret")}`;
  let data = {
    hash_id: resume_id
  };
  return fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (!response.ok) {
        message.error("获取失败");
        return null;
      }
      return response.json();
    })
    .then(json => {
      if (json) {
        // console.log(json)
        if (json["parsing_result"] && json["parsing_result"]["contact_info"]) {
          cb(json);
          message.success("获取联系方式成功");
        } else {
          message.error("获取失败");
        }
      } else {
        message.error("获取失败");
      }
    })
    .catch(error => {
      message.error("获取失败");
    });
}

export function checkPurchased(resume_ids, cb) {
  let url = `${BACKEND_URL}/v1/resumer/get_contact?id=${store.get(
    "user_id"
  )}&secret=${store.get("user_secret")}`;
  let data = {
    hash_ids: resume_ids
  };
  return fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (!response.ok) {
        // message.error("获取失败");
        return null;
      }
      return response.json();
    })
    .then(json => {
      if (json) {
        // console.log(json)
        cb(json);
      } else {
        // message.error("获取失败");
      }
    })
    .catch(error => {
      // message.error("获取失败");
    });
}
