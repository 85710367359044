import React from "react";
import { Input, Icon, Spin } from "antd";
import PropTypes from "prop-types";
import "./style/index.less";

class CandidateList extends React.Component {
  constructor(props) {
    super(props);
  }

  getNestedObject(nested_obj, path_string) {
    let path_list = path_string.split(".");
    return path_list.reduce(
      (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
      nested_obj
    );
  }

  constructCandidateResult(candidates, showCandidate) {
    let getNestedObject = this.getNestedObject;
    const list = candidates.map(function(item, key) {
      let source = item.parsing_result;
      let hash_id = item.hash_id;
      let highlight = item.highlight || {};
      return (
        <div
          key={key}
          className="candidate-single"
          onClick={showCandidate.bind(this, item)}
        >
          <div className="candidate-single-id">{hash_id}</div>
          <div className="candidate-single-avatar">
            {source.basic_info.gender === "男" ? (
              <img
                src={require("../../assets/img/boy.svg")}
                alt="avatar"
                height="64"
                width="64"
              />
            ) : (
              <div>
                {source.basic_info.gender === "女" ? (
                  <img
                    src={require("../../assets/img/girl.svg")}
                    alt="avatar"
                    height="64"
                    width="64"
                  />
                ) : (
                  <img
                    src={require("../../assets/img/nobody.svg")}
                    alt="avatar"
                    height="64"
                    width="64"
                  />
                )}
              </div>
            )}
            {source.purchased ? (
              <div className="purchased-sign">已获取</div>
            ) : null}
          </div>
          <div className="candidate-single-info">
            <div className="candidate-single-basic-info">
              <h1>{source.basic_info.name || "某求职者"}</h1>
              {[
                "basic_info.current_position",
                "basic_info.degree",
                "basic_info.current_location"
              ].map(function(item, key) {
                if (item in highlight) {
                  return (
                    <div style={{ display: "flex" }} key={key}>
                      <h4 key={key}>
                        <span>|</span>
                      </h4>
                      <h4
                        dangerouslySetInnerHTML={{ __html: highlight[item] }}
                      />
                    </div>
                  );
                } else {
                  let value = getNestedObject(source, item);
                  if (value) {
                    return (
                      <h4 key={key}>
                        <span>|</span>
                        {value}
                      </h4>
                    );
                  }
                }
              })}
              {source.basic_info.num_work_experience ? (
                <h4>
                  <span>|</span>
                  {source.basic_info.num_work_experience}
                  年工作经验
                </h4>
              ) : null}
            </div>
            <div className="candidate-single-exp">
              <img
                src={require("../../assets/img/work_experience.svg")}
                alt="work_experience"
                height="18"
                width="18"
              />
              {[
                "basic_info.current_company",
                "basic_info.current_position"
              ].map(function(item, key) {
                if (item in highlight) {
                  return (
                    <div style={{ display: "flex" }} key={key}>
                      <h4 key={key}>
                        <span>|</span>
                      </h4>
                      <h4
                        dangerouslySetInnerHTML={{ __html: highlight[item] }}
                      />
                    </div>
                  );
                } else {
                  let value = getNestedObject(source, item);
                  if (value) {
                    return (
                      <h4 key={key}>
                        <span>|</span>
                        {value}
                      </h4>
                    );
                  }
                }
              })}
            </div>
            <div className="candidate-single-exp">
              <img
                src={require("../../assets/img/education_experience.svg")}
                alt="education_experience"
                height="18"
                width="18"
              />
              {[
                "basic_info.school_name",
                "basic_info.major",
                "basic_info.major.degree"
              ].map(function(item, key) {
                if (item in highlight) {
                  return (
                    <div style={{ display: "flex" }} key={key}>
                      <h4 key={key}>
                        <span>|</span>
                      </h4>
                      <h4
                        dangerouslySetInnerHTML={{ __html: highlight[item] }}
                      />
                    </div>
                  );
                } else {
                  let value = getNestedObject(source, item);
                  if (value) {
                    return (
                      <h4 key={key}>
                        <span>|</span>
                        {value}
                      </h4>
                    );
                  }
                }
              })}
            </div>
            <div className="candidate-skills">
              {"others.skills" in highlight ? (
                <span>
                  {highlight["others.skills"].map((item, key) => (
                    <span
                      key={key}
                      className="candidate-skills-tag"
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                  ))}
                  {source.others.skills.map(function(item, key) {
                    if (
                      highlight["others.skills"]
                        .map(x =>
                          x
                            .replace("</span>", "")
                            .replace("<span id='highlighter'>", "")
                        )
                        .includes(item)
                    ) {
                      return <span key={key} />;
                    } else {
                      return (
                        <span className="candidate-skills-tag" key={key}>
                          {item}
                        </span>
                      );
                    }
                  })}
                </span>
              ) : (
                <span>
                  {source.others.skills.map(function(item, key) {
                    return (
                      <span className="candidate-skills-tag" key={key}>
                        {" "}
                        {item}{" "}
                      </span>
                    );
                  })}
                </span>
              )}
            </div>
          </div>
        </div>
      );
    });
    return <div className="candidates-list-div-result">{list}</div>;
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 30 }} spin />;
    return (
      <div>
        {this.props.loading ? (
          <div className="candidate-list-page-body-loading-inside">
            <Spin size="large" indicator={antIcon} />
            <div className="candidate-list-page-body-loading-text">
              搜索中...
            </div>
          </div>
        ) : (
          <div className="candidate-list-div">
            {this.constructCandidateResult(
              this.props.candidates,
              this.props.showCandidate
            )}
          </div>
        )}
      </div>
    );
  }
}

export default CandidateList;
CandidateList.propTypes = {
  candidates: PropTypes.array.isRequired,
  showCandidate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
