import React, { Component } from "react";
import PropTypes from "prop-types";
import { Drawer } from "antd";

import Resume from "./Resume";

class CandidateDetail extends Component {
  constructor(props) {
    super(props);
  }

  handleClose(e) {
    this.props.onClose(e);
  }

  render() {
    return (
      <Drawer
        title=""
        width={800}
        closable={false}
        onClose={this.handleClose.bind(this)}
        visible={this.props.visible}
        style={{ padding: "0px" }}
        className="resume-drawer-div"
        destroyOnClose={true}
      >
        {this.props.detail ? (
          <Resume
            resume={this.props.detail}
            resume_id={this.props.resume_id}
            updateCandidateContactInfo={this.props.updateCandidateContactInfo.bind(
              this
            )}
          />
        ) : null}
      </Drawer>
    );
  }
}

export default CandidateDetail;
CandidateDetail.propTypes = {
  visible: PropTypes.bool,
  detail: PropTypes.object,
  onClose: PropTypes.func,
  updateCandidateContactInfo: PropTypes.func
};
