import React from "react";

import {
  BASIC_INFO,
  EDUCATION,
  WORK,
  SOCIAL,
  TRAINING,
  PROJECT,
  OTHERS
} from "./Constant";

function __parserTimeRange(result) {
  let start_time = result.start_time_year || "";
  if (result.start_time_month) {
    start_time = start_time + "." + result.start_time_month;
  }
  let end_time = result.end_time_year || "";
  if (result.end_time_month) {
    end_time = end_time + "." + result.end_time_month;
  }
  if (result.still_active) {
    end_time = "至今";
  }
  if (start_time || end_time) {
    return start_time + " - " + end_time;
  } else {
    return "";
  }
}

function __parseStartTime(result) {
  let start_time = result.start_time_year || "";
  if (result.start_time_month) {
    start_time = start_time + "." + result.start_time_month;
  }
  return start_time;
}

function __parseEndTime(result) {
  let end_time = result.end_time_year || "";
  if (result.end_time_month) {
    end_time = end_time + "." + result.end_time_month;
  }
  if (result.still_active) {
    end_time = "至今";
  }
  return end_time;
}

function __primarySection(single_result, type) {
  let primary_title_name = "";
  switch (type) {
    case "education":
      primary_title_name = single_result.school_name || "大学";
      break;
    case "work":
      primary_title_name = single_result.company_name || "公司";
      break;
    case "social":
      primary_title_name = single_result.organization_name || "公司/学校/社团";
      break;
    case "project":
      primary_title_name = single_result.project_name || "项目";
      break;
    case "training":
      primary_title_name = single_result.organization_name || "培训机构";
      break;
    default:
      primary_title_name = "";
      break;
  }
  return (
    <div className="resume-primary-section-header">
      <div className="resume-primary-section-header-left">
        <span className="large-dot" />
        <span className="resume-primary-section-title-name">
          {primary_title_name}
        </span>
      </div>
      <div className="resume-primary-section-time">
        {__parserTimeRange(single_result)}
      </div>
    </div>
  );
}

function __secondarySection(single_result, mapping) {
  let start_time = __parseStartTime(single_result);
  let end_time = __parseEndTime(single_result);
  let result_list = [];
  if (start_time) {
    result_list.push(["开始时间：", start_time]);
  }
  if (start_time) {
    result_list.push(["结束时间：", end_time]);
  }
  Object.keys(mapping).map(function(key, index) {
    if (single_result[key]) {
      let temp_val = [mapping[key], single_result[key]];
      result_list.push(temp_val);
    }
    return null;
  });
  return (
    <div className="resume-secondary-section">
      {result_list.map(function(key, index) {
        return (
          <div className="resume-secondary-section-single-term" key={key}>
            <div className="resume-secondary-section-single-term-key">
              {key[0]}
              <span className="resume-secondary-section-single-term-value">
                {key[1]}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function __descriptionSection(single_experience, type) {
  let description_key = "描述";
  switch (type) {
    case "work":
      description_key = "工作描述：";
      break;
    case "social":
      description_key = "工作描述：";
      break;
    case "project":
      description_key = "工作描述：";
      break;
    case "training":
      description_key = "项目描述：";
      break;
    default:
      description_key = "描述：";
      break;
  }
  return (
    <div className="resume-description-section">
      <div className="resume-description-section-key">{description_key}</div>
      <div className="resume-description-section-value">
        {single_experience.description.split("\n").map(function(key, index) {
          return <div key={index}>{key}</div>;
        })}
      </div>
    </div>
  );
}

export function generateHeaderSubInfo(array) {
  let showingResult = [];
  for (let i = 0; i < array.length; i++) {
    if (i === array.length - 1) {
      showingResult.push(<div key={i.toString()}>{array[i]}</div>);
    } else {
      showingResult.push(<div key={i.toString()}>{array[i]}</div>);
      showingResult.push(
        <div key={(i + 1000).toString()} className="small-dot" />
      );
    }
  }
  return showingResult;
}

export function generateBasicInfo(basic_info, contact_info) {
  const new_basic = { ...basic_info, ...contact_info };
  let first_col = [];
  let second_col = [];
  let counter = 0;
  Object.keys(BASIC_INFO).map(function(key, index) {
    if (new_basic[key]) {
      counter = counter + 1;
      if (key === "num_work_experience") {
        new_basic[key] = new_basic[key] + "年";
      }
      let temp_val = [BASIC_INFO[key], new_basic[key]];
      if (counter % 2 === 1) {
        first_col.push(temp_val);
      } else {
        second_col.push(temp_val);
      }
    }
    return null;
  });
  const first_col_div = first_col.map(function(key, index) {
    return (
      <div className="single-term" key={index}>
        <div className="small-dot" />
        <div className="single-term-key">
          {key[0]}
          <span className="single-term-value">{key[1]}</span>
        </div>
      </div>
    );
  });
  const second_col_div = second_col.map(function(key, index) {
    return (
      <div className="single-term" key={index}>
        <div className="small-dot" />
        <div className="single-term-key">
          {key[0]}
          <span className="single-term-value">{key[1]}</span>
        </div>
      </div>
    );
  });
  return (
    <div className="basic-info-body">
      <div className="basic-info-col">{first_col_div}</div>
      <div className="basic-info-col">{second_col_div}</div>
    </div>
  );
}

export function generateEducation(education) {
  const result = education.map(function(single_experience, index) {
    return (
      <div className="resume-single-experience-section" key={index}>
        {__primarySection(single_experience, "education")}
        {__secondarySection(single_experience, EDUCATION)}
      </div>
    );
  });
  return result;
}

export function generateWork(work) {
  const result = work.map(function(single_experience, index) {
    return (
      <div className="resume-single-experience-section" key={index}>
        {__primarySection(single_experience, "work")}
        {__secondarySection(single_experience, WORK)}
        {single_experience.description.length > 0
          ? __descriptionSection(single_experience, "work")
          : null}
      </div>
    );
  });
  return result;
}

export function generateSocial(social) {
  const result = social.map(function(single_experience, index) {
    return (
      <div className="resume-single-experience-section" key={index}>
        {__primarySection(single_experience, "social")}
        {__secondarySection(single_experience, SOCIAL)}
        {single_experience.description.length > 0
          ? __descriptionSection(single_experience, "social")
          : null}
      </div>
    );
  });
  return result;
}

export function generateProject(project) {
  const result = project.map(function(single_experience, index) {
    return (
      <div className="resume-single-experience-section" key={index}>
        {__primarySection(single_experience, "project")}
        {__secondarySection(single_experience, PROJECT)}
        {single_experience.description.length > 0
          ? __descriptionSection(single_experience, "project")
          : null}
      </div>
    );
  });
  return result;
}

export function generateTraining(training) {
  const result = training.map(function(single_experience, index) {
    return (
      <div className="resume-single-experience-section" key={index}>
        {__primarySection(single_experience, "training")}
        {__secondarySection(single_experience, TRAINING)}
        {single_experience.description.length > 0
          ? __descriptionSection(single_experience, "training")
          : null}
      </div>
    );
  });
  return result;
}

export function generateOthers(others) {
  let others_list = [];
  Object.keys(OTHERS).map(function(key, index) {
    if (others[key].length > 0) {
      let temp_val = [OTHERS[key], others[key].join("，")];
      others_list.push(temp_val);
    }
    return null;
  });
  if (others.self_evaluation) {
    others_list.push(["自我评价：", others.self_evaluation]);
  }
  if (others_list.length > 0) {
    return (
      <div>
        <div className="resume-section-header">
          <div className="small-line-left-archive" />
          <h4 className="section-word">补充信息</h4>
          <div className="small-line-right-archive" />
        </div>
        <div className="resume-single-experience-section">
          {others_list.map(function(key, index) {
            return (
              <div className="resume-description-section" key={key}>
                <span className="resume-description-section-key">{key[0]}</span>
                <span className="resume-description-section-value-no-max ">
                  {key[1]}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
