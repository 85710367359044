
export const BasicInfoFilterList = [
  { name: "name", chinese_name: "姓名", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "gender", chinese_name: "性别", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "min_age", chinese_name: "最低年龄", multi: false, type: "int", has_exact_match: false, has_or_match: false},
  { name: "max_age", chinese_name: "最高年龄", multi: false, type: "int", has_exact_match: false, has_or_match: false},
  { name: "current_location", chinese_name: "当前城市", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "expect_location", chinese_name: "期望城市", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "current_position", chinese_name: "当前职位", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "desired_position", chinese_name: "期望职位", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "industry", chinese_name: "当前所在行业", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "current_company", chinese_name: "当前所在公司", multi: true, type: "string", has_exact_match: true, has_or_match: false},
  { name: "political_status", chinese_name: "政治面貌", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "early_graduate_year", chinese_name: "最早毕业时间", multi: false, type: "string", has_exact_match: false, has_or_match: false},
  { name: "latest_graduate_year", chinese_name: "最晚毕业时间", multi: false, type: "string", has_exact_match: false, has_or_match: false}
];

export const ContactInfoFilterList = [
  { name: "phone_number", chinese_name: "手机号码", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "email", chinese_name: "邮箱", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "QQ", chinese_name: "QQ号", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "wechat", chinese_name: "微信", multi: true, type: "string", has_exact_match: false, has_or_match: false}
];

export const YearsOfExperience = [
  { name: "min_work_exp", chinese_name: "最低工作年限", multi: false, type: "int", has_exact_match: false, has_or_match: false},
  { name: "max_work_exp", chinese_name: "最高工作年限", multi: false, type: "int", has_exact_match: false, has_or_match: false}
];

export const EducationExperienceFilterList = [
  { name: "school_name", chinese_name: "学校名称", multi: true, type: "string", has_exact_match: true, has_or_match: false},
  { name: "degree", chinese_name: "学历", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "major", chinese_name: "专业", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "school_level", chinese_name: "学校等级", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "courses", chinese_name: "所学课程", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "abroad_country", chinese_name: "海外国家", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "abroad", chinese_name: "是否海外", multi: true, type: "int", has_exact_match: false, has_or_match: false}
];

export const WorkExperienceFilterList = [
  { name: "company_name", chinese_name: "公司名称", multi: true, type: "string", has_exact_match: true, has_or_match: false},
  { name: "job_function", chinese_name: "职能", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "industry", chinese_name: "行业", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "job_title", chinese_name: "职位名", multi: true, type: "string", has_exact_match: true, has_or_match: false},
  { name: "department", chinese_name: "部门名", multi: true, type: "string", has_exact_match: false, has_or_match: false},
  { name: "work_description", chinese_name: "工作经历描述", multi: true, type: "string", has_exact_match: false, has_or_match: false}
];

export const OthersFilterList = [
  { name: "social_description", chinese_name: "社会经历描述", multi: true, type: "string", has_exact_match: false, has_or_match: true},
  { name: "project_name", chinese_name: "项目名称", multi: true, type: "string", has_exact_match: true, has_or_match: false},
  { name: "project_description", chinese_name: "项目经历描述", multi: true, type: "string", has_exact_match: false, has_or_match: true},
  { name: "organization_name", chinese_name: "培训机构名称", multi: true, type: "string", has_exact_match: true, has_or_match: false},
  { name: "training_description", chinese_name: "培训经历描述", multi: true, type: "string", has_exact_match: false, has_or_match: true},
  { name: "skills", chinese_name: "技能", multi: true, type: "string", has_exact_match: false, has_or_match: true},
  { name: "it_skills", chinese_name: "计算机技能", multi: true, type: "string", has_exact_match: false, has_or_match: true},
  { name: "business_skills", chinese_name: "商业技能", multi: true, type: "string", has_exact_match: false, has_or_match: true},
  { name: "language", chinese_name: "语言", multi: true, type: "string", has_exact_match: false, has_or_match: true},
  { name: "certificate", chinese_name: "证书", multi: true, type: "string", has_exact_match: false, has_or_match: true},
  { name: "awards", chinese_name: "奖项", multi: true, type: "string", has_exact_match: false, has_or_match: true},
  { name: "self_evaluation", chinese_name: "自我评价", multi: true, type: "string", has_exact_match: false, has_or_match: true},
  { name: "resume_rawtext", chinese_name: "全文搜索", multi: true, type: "string", has_exact_match: false, has_or_match: true}
];
