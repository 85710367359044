import React from "react";
import PropTypes from "prop-types";
import {
  generateHeaderSubInfo,
  generateContactInfo,
  generateBasicInfo,
  generateEducation,
  generateWork,
  generateSocial,
  generateProject,
  generateTraining,
  generateOthers
} from "./Util";
import { ERROR_HEADER } from "./Constant";
import "./style/index.less";
import { purchaseResume } from "../../actions/candidates";
import { DEPLOYMENT_ENV } from "../../config";

class GenerateResume extends React.PureComponent {
  // static propTypes = {
  //   result: PropTypes.object.isRequired,
  //   language: PropTypes.string.isRequired,
  // }

  constructor(props) {
    super(props);
  }

  onClickShowCode() {
    purchaseResume(this.props.result_id, this.updateContactInfo.bind(this));
  }

  updateContactInfo(data) {
    let phone_number = data["phone_number"];
    let email = data["email"];
    this.props.updateCandidateContactInfo(data);
  }

  render() {
    let result = this.props.result.parsing_result;
    return (
      <div className="single-resume-div">
        <div className="resume-div-header">
          {result && Object.keys(result).length > 0 ? (
            <div className="resume-div-header-language">中文简历</div>
          ) : null}
          {DEPLOYMENT_ENV === "external" ? (
            <div>
              {result.contact_info.phone_number || result.contact_info.email ? (
                <div>
                  {result.purchased ? (
                    <div className="purchased">已获取</div>
                  ) : (
                    <div className="resume-div-header-code-button">
                      <a
                        className="common-button"
                        onClick={this.onClickShowCode.bind(this)}
                      >
                        查看联系方式
                      </a>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div>
          <div className="header-info">
            {this.props.avatar_data ? (
              <div className="avatar">
                {this.props.avatar_data ? (
                  <img
                    src={`data:image/png;base64, ${this.props.avatar_data}`}
                    alt="小析智能简历头像"
                    className="avatar-img"
                  />
                ) : null}
              </div>
            ) : (
              <div className="avatar">
                {result.basic_info.gender ? (
                  <img
                    src={require(result.basic_info.gender === "女"
                      ? "../../assets/avatars/girl.svg"
                      : "../../assets/avatars/boy.svg")}
                    alt="小析智能简历头像"
                    className="avatar-img"
                  />
                ) : (
                  <img
                    src={require("../../assets/avatars/nobody.svg")}
                    alt="小析智能简历头像"
                    className="avatar-img"
                  />
                )}
              </div>
            )}
            <div>
              <h2 className="name">{result.basic_info.name || "某求职者"} </h2>
              <div className="header-info-subline">
                {result.basic_info.age && (
                  <div>
                    <img
                      src={require("../../assets/resume-icons/user.svg")}
                      alt="age"
                    />
                    <span>{result.basic_info.age}岁</span>
                  </div>
                )}
                {result.basic_info.gender && (
                  <div>
                    <img
                      src={require("../../assets/resume-icons/gender.svg")}
                      alt="gender"
                    />
                    <span>{result.basic_info.gender}</span>
                  </div>
                )}
                {result.contact_info.phone_number && (
                  <div>
                    <img
                      src={require("../../assets/resume-icons/phone.svg")}
                      alt="phone_number"
                    />
                    <span>{result.contact_info.phone_number}</span>
                  </div>
                )}
                {result.contact_info.email && (
                  <div>
                    <img
                      src={require("../../assets/resume-icons/email.svg")}
                      alt="email"
                    />
                    <span>{result.contact_info.email}</span>
                  </div>
                )}
                {result.basic_info.num_work_experience !== null && (
                  <div>
                    <img
                      src={require("../../assets/resume-icons/work_small.svg")}
                      alt="work"
                    />
                    <span>
                      {result.basic_info.num_work_experience}年工作经验
                    </span>
                  </div>
                )}
                {result.basic_info.degree && (
                  <div>
                    <img
                      src={require("../../assets/resume-icons/education_small.svg")}
                      alt="degree"
                    />
                    <span>{result.basic_info.degree}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="zh-en-resume">
          <div>
            <div className="basic-info">
              <div className="resume-section-header">
                <div className="small-line-left-archieve" />
                <h4 className="section-word">基本信息</h4>
                <div className="small-line-right-archieve" />
              </div>
              {generateBasicInfo(result.basic_info, result.contact_info)}
            </div>
            <div className="education">
              {result.education_experience.length > 0 ? (
                <div className="resume-section-header">
                  <div className="small-line-left-archieve" />
                  <h4 className="section-word">教育背景</h4>
                  <div className="small-line-right-archieve" />
                </div>
              ) : null}
              {generateEducation(result.education_experience)}
            </div>
            <div className="work">
              {result.work_experience.length > 0 ? (
                <div className="resume-section-header">
                  <div className="small-line-left-archieve" />
                  <h4 className="section-word">工作经历</h4>
                  <div className="small-line-right-archieve" />
                </div>
              ) : null}
              {generateWork(result.work_experience)}
            </div>
            <div className="social">
              {result.social_experience.length > 0 ? (
                <div className="resume-section-header">
                  <div className="small-line-left-archieve" />
                  <h4 className="section-word">社会经历</h4>
                  <div className="small-line-right-archieve" />
                </div>
              ) : null}
              {generateSocial(result.social_experience)}
            </div>
            <div className="project">
              {result.project_experience.length > 0 ? (
                <div className="resume-section-header">
                  <div className="small-line-left-archieve" />
                  <h4 className="section-word">项目经历</h4>
                  <div className="small-line-right-archieve" />
                </div>
              ) : null}
              {generateProject(result.project_experience)}
            </div>
            <div className="training">
              {result.training_experience.length > 0 ? (
                <div className="resume-section-header">
                  <div className="small-line-left-archieve" />
                  <h4 className="section-word">培训经历</h4>
                  <div className="small-line-right-archieve" />
                </div>
              ) : null}
              {generateTraining(result.training_experience)}
            </div>
            <div className="others">{generateOthers(result.others)}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default function Resume(data) {
  let resume = data.resume;
  let resume_id = data.resume_id;
  let updateCandidateContactInfo = data.updateCandidateContactInfo;
  return (
    <div className="resume">
      <div className="resume-div">
        <div>
          <GenerateResume
            result={resume}
            result_id={resume_id}
            updateCandidateContactInfo={updateCandidateContactInfo}
            language="chinese"
          />
        </div>
      </div>
    </div>
  );
}
